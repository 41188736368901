import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import DOMPurify from "dompurify";
import axios from "axios";
import styled from "styled-components";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { FaRegCalendarAlt, FaEye, FaArrowRight } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import classNames from "classnames";
import Breadcrumbs from "../Breadcrumbs"; // Example import
import NewsType from "./NewsType";
import Region from "./Region";
import Container from "../../layout/Container";
import Title from "../Title";
import classes from "./News.module.scss";

// Styled Components
const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  gap: 10px;
`;

const PageButton = styled.button`
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  background-color: ${({ active }) => (active ? "#007BFF" : "#f0f0f0")};
  color: ${({ active }) => (active ? "#fff" : "#333")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  transition: background-color 0.3s, color 0.3s;
`;

const Ellipsis = styled.span`
  font-size: 16px;
  color: "#333";
`;

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const handleClick = (page) => {
    if (page !== currentPage && page > 0 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    const maxVisiblePages = 3;
    const startPage = Math.max(
      currentPage - Math.floor(maxVisiblePages / 2),
      1
    );
    const endPage = Math.min(startPage + maxVisiblePages - 2, totalPages);

    if (startPage > 2) {
      pages.push(
        <PageButton
          key={1}
          active={1 === currentPage}
          onClick={() => handleClick(2)}
        >
          1
        </PageButton>
      );
      if (startPage > 2) {
        pages.push(<Ellipsis key="ellipsis-start">...</Ellipsis>);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <PageButton
          key={i}
          active={i === currentPage}
          onClick={() => handleClick(i)}
        >
          {i}
        </PageButton>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(<Ellipsis key="ellipsis-end">...</Ellipsis>);
      }
      pages.push(
        <PageButton
          key={totalPages}
          active={totalPages === currentPage}
          onClick={() => handleClick(totalPages)}
        >
          {totalPages}
        </PageButton>
      );
    }

    return pages;
  };

  return (
    <PaginationContainer>
      <PageButton
        disabled={currentPage === 1}
        onClick={() => handleClick(currentPage - 1)}
      >
        <IoIosArrowBack />
      </PageButton>
      {renderPageNumbers()}
      <PageButton
        disabled={currentPage === totalPages}
        onClick={() => handleClick(currentPage + 1)}
      >
        <IoIosArrowForward />
      </PageButton>
    </PaginationContainer>
  );
};

const News = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [allData, setAllData] = useState([]); // Store all data for filtering
  const [data, setData] = useState([]); // Current page data
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]); // Filtered data
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedRegion, setSelectedRegion] = useState("all");
  const [selectedCategory, setSelectedCategory] = useState({
    id: "all",
    name: t("AllCategory"),
  });
  const [openDropdown, setOpenDropdown] = useState(null);
  const itemsPerPage = location.pathname === "/" ? 3 : 6;

  // Fetch all data once
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        let allResults = [];
        let nextUrl = "https://api.adolat.uz/news/";
        while (nextUrl) {
          const response = await axios.get(nextUrl);
          allResults = [...allResults, ...response.data.results];
          nextUrl = response.data.next;
        }
        setAllData(allResults);
        setTotalItems(allResults.length);
      } catch (error) {
        console.log("Error fetching all news data:", error);
      }
    };

    fetchAllData();
  }, []);

  // Filter data
  useEffect(() => {
    const filtered = allData
      .filter((item) => {
        const regionMatch =
          selectedRegion === "all" ||
          item.region === parseInt(selectedRegion, 10);
        const categoryMatch =
          selectedCategory.id === "all" ||
          item.category === parseInt(selectedCategory.id, 10);
        const searchMatch =
          !searchValue ||
          (getLocalizedText(item, "title") &&
            getLocalizedText(item, "title")
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (getLocalizedText(item, "description") &&
            getLocalizedText(item, "description")
              .toLowerCase()
              .includes(searchValue.toLowerCase()));

        return regionMatch && categoryMatch && searchMatch;
      })
      .sort((a, b) => new Date(b.created) - new Date(a.created));

    setFilteredData(filtered);
  }, [searchValue, selectedCategory, allData, selectedRegion]);

  // Paginate filtered data
  useEffect(() => {
    const offset = currentPage * itemsPerPage;
    const currentPageData = filteredData.slice(offset, offset + itemsPerPage);
    setData(currentPageData);
  }, [filteredData, currentPage, itemsPerPage]);

  const handleSearchChange = (e) => setSearchValue(e.target.value);

  const handleDropdownToggle = (dropdownName) => {
    setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
  };

  const getLocalizedText = (item, field) => {
    const localizedField = `${field}_${i18n.language}`;
    const defaultField = `${field}_uz`;
    return item[localizedField] || item[defaultField] || t("NoData");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const getFirstWords = (text, wordCount) => {
    if (!text) return "";
    const cleanedText = text
      .replace(/<\/?(br|p|li|ul|ol|h[1-6])\b[^>]*>/gi, " ") // Remove specific HTML tags
      .replace(/&nbsp;/gi, " ") // Replace HTML non-breaking spaces with regular spaces
      .replace(/\s+/g, " ") // Remove extra whitespace
      .trim();
    const words = cleanedText.split(" ");
    const firstWords = words.slice(0, wordCount).join(" ");
    return words.length > wordCount ? `${firstWords}...` : firstWords;
  };

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);
  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const HTMLContent = ({ content }) => {
    const decodedContent = decodeHtml(content);
    const sanitizedContent = DOMPurify.sanitize(decodedContent);

    return (
      <div
        className="custom-html-content"
        style={{
          maxHeight: "80px",
          fontSize: "18px",
          lineHeight: "1.5",
          color: "##74767b",
          fontFamily: "Montserrat",
        }}
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      />
    );
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{location.pathname === "/news" ? t("TheNews") : ""}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Container>
        <div className={classes.news}>
          <Breadcrumbs
            items={[{ name: t("HomePage"), link: "/" }, { name: t("TheNews") }]}
          />
          <div className={classes["news-title"]}>
            <Title>{t("TheNews")}</Title>
          </div>
          <div className={classes["news-filter"]}>
            <div className={classes["news-filter__dropdowns"]}>
              <NewsType
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                isOpen={openDropdown === "NewsType"}
                onToggle={() => handleDropdownToggle("NewsType")}
              />
              <Region
                selectedRegion={selectedRegion}
                setSelectedRegion={setSelectedRegion}
                isOpen={openDropdown === "Region"}
                onToggle={() => handleDropdownToggle("Region")}
              />
            </div>
            <div className={classes["news-filter__form"]}>
              <input
                type="text"
                placeholder={t("search")}
                value={searchValue}
                onChange={handleSearchChange}
              />
              <button>
                <CiSearch />
              </button>
            </div>
          </div>
          <div className={classes["news-content"]}>
            {Array.isArray(data) &&
              data.map((item, index) => (
                <div
                  data-aos="fade-up"
                  data-aos-duration={1000 + index * 1000}
                  key={item.id}
                  className={classNames(classes["news-content__items"])}
                >
                  <Link to={`/news/${item.id}`}>
                    <figure>
                      <img
                        src={item.poster_image || "/path/to/placeholder.jpg"}
                        alt={getLocalizedText(item, "title")}
                      />
                    </figure>
                    <div className={classes["body"]}>
                      <h3>
                        {getFirstWords(getLocalizedText(item, "title"), 5)}
                      </h3>
                      <p className={classes["calendar"]}>
                        <FaRegCalendarAlt />
                        {item.created
                          ? formatDate(item.created)
                          : formatDate(item.created_at)}
                      </p>
                      <HTMLContent
                        content={getFirstWords(
                          getLocalizedText(item, "description"),
                          10
                        )}
                      />
                      <p className={classes["news__views"]}>
                        {item.views} <FaEye />
                      </p>
                      <Link to={`/news/${item.id}`}>
                        {t("more")} <FaArrowRight />
                      </Link>
                    </div>
                  </Link>
                </div>
              ))}
          </div>
          {location.pathname === "/news" ? (
            <Pagination
              currentPage={currentPage + 1}
              totalPages={pageCount}
              onPageChange={(page) => setCurrentPage(page - 1)}
            />
          ) : (
            <div className={classes["news__link-block"]}>
              <Link to="/news" className={classes["news__link"]}>
                {t("more")}
              </Link>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default News;
