import { useTranslation } from "react-i18next";
import { FaShareNodes } from "react-icons/fa6";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaShoppingBag } from "react-icons/fa";
import { RiFileList2Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import Container from "../../layout/Container";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import classes from "./Party.module.scss";

const Party = () => {
  const { t } = useTranslation();
  const data = [
    {
      icon: <RiFileList2Line />,
      title: t("partyjournal"),
      link: "https://adolat24.uz/",
      item: "_blank",
    },
    {
      icon: <FaShoppingBag />,
      title: t("eshop"),
      link: "/store",
    },
    {
      icon: <FaShareNodes />,
      title: t("social"),
      link: "https://t.me/adolat_uz",
    },
  ];

  return (
    <div className={classes.party}>
      <Container className={classes["party-content"]}>
        <a
          rel="noreferrer"
          className={classes["party-items"]}
          href="https://azolik.adolat.uz/"
          target="_blank"
        >
          <p>
            <svg
              fill="#fff"
              strokeWidth="0"
              viewBox="0 0 24 24"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M10.27 12h3.46a1.5 1.5 0 0 0 1.48-1.75l-.3-1.79a2.951 2.951 0 0 0-5.82.01l-.3 1.79c-.15.91.55 1.74 1.48 1.74zM1.66 11.11c-.13.26-.18.57-.1.88.16.69.76 1.03 1.53 1h1.95c.83 0 1.51-.58 1.51-1.29 0-.14-.03-.27-.07-.4-.01-.03-.01-.05.01-.08.09-.16.14-.34.14-.53 0-.31-.14-.6-.36-.82-.03-.03-.03-.06-.02-.1.07-.2.07-.43.01-.65a1.12 1.12 0 0 0-.99-.74.09.09 0 0 1-.07-.03C5.03 8.14 4.72 8 4.37 8c-.3 0-.57.1-.75.26-.03.03-.06.03-.09.02a1.24 1.24 0 0 0-1.7 1.03c0 .02-.01.04-.03.06-.29.26-.46.65-.41 1.05.03.22.12.43.25.6.03.02.03.06.02.09zM16.24 13.65c-1.17-.52-2.61-.9-4.24-.9-1.63 0-3.07.39-4.24.9A2.988 2.988 0 0 0 6 16.39V18h12v-1.61c0-1.18-.68-2.26-1.76-2.74zM1.22 14.58A2.01 2.01 0 0 0 0 16.43V18h4.5v-1.61c0-.83.23-1.61.63-2.29-.37-.06-.74-.1-1.13-.1-.99 0-1.93.21-2.78.58zM22.78 14.58A6.95 6.95 0 0 0 20 14c-.39 0-.76.04-1.13.1.4.68.63 1.46.63 2.29V18H24v-1.57c0-.81-.48-1.53-1.22-1.85zM22 11v-.5c0-1.1-.9-2-2-2h-2c-.42 0-.65.48-.39.81l.7.63c-.19.31-.31.67-.31 1.06 0 1.1.9 2 2 2s2-.9 2-2z"></path>
            </svg>
          </p>
          <p>{t("party-subs")}</p>
        </a>
        <div className={classes["swiper-container"]}>
          <Swiper
            className={classes["party-swiper"]}
            slidesPerView={1}
            spaceBetween={30}
            breakpoints={{
              550: { slidesPerView: 1, spaceBetween: 1 },
              678: { slidesPerView: 1, spaceBetween: 1 },
              992: { slidesPerView: 2, spaceBetween: 1 },
              1200: { slidesPerView: 3 },
              1600: { slidesPerView: 3, spaceBetween: 1 },
            }}
            autoplay={{ delay: 3000 }}
            speed={700}
            pagination={{ clickable: true }}
            modules={[Autoplay]}
          >
            {data.map((item, index) => (
              <SwiperSlide className={classes["slider__inner"]} key={index}>
                <Link
                  to={item.link}
                  target={item.item}
                  className={classes["party-item"]}
                >
                  <p>{item.icon}</p>
                  <p>{item.title}</p>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Container>
    </div>
  );
};

export default Party;
