import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import AOS from "aos";
import i18next from "./i18next";
import Header from "./components/Header";
import HeaderTop from "./components/HeaderTop";
import Footer from "./components/Footer";
import "aos/dist/aos.css";
import "./index.scss";
import Home from "./pages/Home";

const Candidates = lazy(() => import("./pages/Candidates"));
const Election = lazy(() => import("./pages/Election"));
const Committee = lazy(() => import("./pages/Committee"));
const CentralApparatus = lazy(() => import("./pages/CentralApparatus"));
const NewsPage = lazy(() => import("./pages/NewsPage"));
const PoliticalCouncil = lazy(() => import("./pages/PoliticalCouncil"));
const Store = lazy(() => import("./pages/Store"));
const Cart = lazy(() => import("./pages/Cart"));
const TheMostReadNews = lazy(() => import("./pages/TheMostReadNews"));
const Announcements = lazy(() => import("./pages/Announcements"));
const Deputs = lazy(() => import("./pages/Deputs"));
const AppointedSenators = lazy(() => import("./pages/AppointedSenators"));
const ElecedSenators = lazy(() => import("./pages/ElectedSenators"));
const Commission = lazy(() => import("./pages/Commission"));
const ElectionProgram = lazy(() => import("./pages/ElectionProgram"));
const LocalDeputets = lazy(() => import("./pages/LocalDeputets"));
const Faq = lazy(() => import("./pages/Faq"));
const Contact = lazy(() => import("./pages/Contact"));
const PartyProjects = lazy(() => import("./pages/PartyProjects"));
const Sponsorship = lazy(() => import("./pages/SponsorshipPage"));
const PartyHistory = lazy(() => import("./pages/PartyHistory"));
const Wings = lazy(() => import("./pages/Wings"));
const ElectionDynamic = lazy(() => import("./pages/ElectionDynamic"));
const PartyCandidatesDnamic = lazy(() =>
  import("./pages/PartyCandidatesDnamic")
);
const PartyProjectDetails = lazy(() => import("./pages/PartyProjectsDeatils"));
const AnnouncementsDynamic = lazy(() => import("./pages/AnnouncementsDynamic"));
const News = lazy(() => import("./pages/News"));
const PoliticalCouncilPartTwo = lazy(() =>
  import("./pages/PoliticalCouncilPartTwo")
);

const App = () => {
  React.useEffect(() => {
    AOS.init({
      disable: false,
      startEvent: "DOMContentLoaded",
      initClassName: "aos-init",
      animatedClassName: "aos-animate",
      useClassNames: false,
      disableMutationObserver: false,
      debounceDelay: 50,
      throttleDelay: 99,
      offset: 120,
      delay: 0,
      duration: 1000,
      easing: "ease",
      once: false,
      mirror: false,
      anchorPlacement: "top-bottom",
    });
  }, []);

  return (
    <div className="App">
      <I18nextProvider i18n={i18next}>
        <HeaderTop />
        <Header />
        <Suspense fallback={<Home />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/candidates" element={<Candidates />} />
            <Route path="/election" element={<Election />} />
            <Route path="/committee" element={<Committee />} />
            <Route path="/central-apparatus" element={<CentralApparatus />} />
            <Route path="/news" element={<NewsPage />} />
            <Route
              path="/political-councils"
              element={<PoliticalCouncilPartTwo />}
            />
            <Route path="/political-council" element={<PoliticalCouncil />} />
            <Route path="/store" element={<Store />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/most-read-news" element={<TheMostReadNews />} />
            <Route path="/announcements" element={<Announcements />} />
            <Route path="/deputs" element={<Deputs />} />
            <Route path="/appointed-senators" element={<AppointedSenators />} />
            <Route path="/eleced-senators" element={<ElecedSenators />} />
            <Route path="/commission" element={<Commission />} />
            <Route path="/local-deputs" element={<LocalDeputets />} />
            <Route path="/election-program" element={<ElectionProgram />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/party-projects" element={<PartyProjects />} />
            <Route path="/sponsorship" element={<Sponsorship />} />
            <Route path="/party-history" element={<PartyHistory />} />

            <Route path="/wings/:component" element={<Wings />} />
            <Route path="/election/:id" element={<ElectionDynamic />} />
            <Route
              path="/party-candidates/:id"
              element={<PartyCandidatesDnamic />}
            />
            <Route
              path="/party-projects/:id"
              element={<PartyProjectDetails />}
            />
            <Route
              path="/announcements/:id"
              element={<AnnouncementsDynamic />}
            />
            <Route path="/news/:id" element={<News />} />
          </Routes>
        </Suspense>
        <Footer />
      </I18nextProvider>
    </div>
  );
};

export default App;
