import React from "react";
import { useTranslation } from "react-i18next";
import { InstagramEmbed } from "react-social-media-embed";

import Container from "../../../../layout/Container";
import X from "../../../../components/X";
import Title from "../../../../components/Title";

import classes from "./Iframe.module.scss";

const Iframe = () => {
  const { t } = useTranslation();

  return (
    <Container className={classes.iframe}>
      <div className={classes.mw1800}>
        <Title data-aos="fade-right" className={classes.iframe__title}>
          {t("social")}
        </Title>
        <div className={classes.iframeBlocks} data-aos="fade-up">
          <div className={classes.iframeBlock}>
            <p className={classes.iframeText}>Instagram</p>
            <InstagramEmbed
              url="https://www.instagram.com/adolat_sdp/"
              className={classes.iframes}
              width={`100%`}
              height="500"
            />
          </div>
          <div className={classes.iframeBlock}>
            <p className={classes.iframeText}>Facebook</p>
            <iframe
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fsdpu.adolat%2F%3Fref%3Dembed_page&tabs=timeline&width=500&large_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
              width="500"
              height="500"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              allowFullScreen={true}
              style={{
                border: "none",
                overflow: "hidden",
                maxWidth: "500px",
                width: "100%",
              }}
            ></iframe>
          </div>
          <div className={classes.iframeBlock}>
            <p className={classes.iframeText}>Twitter</p>
            <X />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Iframe;
