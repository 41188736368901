import { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import Title from "../../../../components/Title";
import Container from "../../../../layout/Container";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const StyledSwiper = styled(Swiper)`
  .swiper-button-next,
  .swiper-button-prev {
    color: #000;
  }

  .swiper-pagination-bullet-active {
    background: #000;
  }

  .swiper-slide {
    margin-right: 0;
  }
`;

const SliderInner = styled(SwiperSlide)`
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: 200px;
  }
`;

const Partners = () => {
  const { t } = useTranslation();

  const [data, setData] = useState(null);

  useEffect(() => {
    const handleGetData = async () => {
      const res = await axios.get("https://api.adolat.uz/partner/");
      setData(res.data);
    };
    handleGetData();
  }, []);
  return (
    <Container>
      <Title>{t("Partners")}</Title>
      <StyledSwiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={50}
        autoplay={{ delay: 2000 }}
        speed={2400}
        slidesPerView={1}
        navigation
        breakpoints={{
          1200: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          576: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        }}
      >
        {Array.isArray(data) &&
          data.map((item) => (
            <SliderInner key={item.id}>
              <a rel="noreferrer" target="_blank" href={item.link}>
                <img src={item.img} alt="" />
              </a>
            </SliderInner>
          ))}
      </StyledSwiper>
    </Container>
  );
};

export default Partners;
